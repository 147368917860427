import React, { Component } from "react"; 

import MailchimpSubscribe from "./mailchimp"



class Subscribe extends Component {
  render() {
    const url =
      "https://datapolicytrust.us20.list-manage.com/subscribe/post?u=a959898c9523af82e2845802e&amp;id=3d5de989a8";
    return (
        <div className="container mr-auto ml-auto text-center ">
       
          {/* <form className="form-inline text-center"> */}
        {/* <h2>Default Form</h2> */}
        <MailchimpSubscribe url={url} />
        {/* <h2>Custom Form</h2>
        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => (
            <CustomForm
              status={status}
              message={message}
              onValidated={formData => subscribe(formData)}
            />
          )}
        /> */}
        {/* </form> */}
        </div>
    );
  }
}
export default Subscribe
